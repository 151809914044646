import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { ToastProvider } from '@veneer/core';
import { LoaderWidget, RootProvider } from '@jarvis/react-portal-addons';
import { getLDClientSideID, getUniqueUserKey } from './utils/ldClient';
import resources from './assets/locale/index';

const App = lazy(() => import(/* webpackPreload: true */ './App'));
const SolutionsListWidget = lazy(() => import(/* webpackPreload: true */ './components/SolutionsListWidget'));

const SolutionsOverview = ({ type, ...rest }) => {
  const [Container, setContainer] = useState(() => ({ children }) => children);
  const [fetchingLD, setFetchingLD] = useState(true);

  const localization = rest.shell?.v1?.localization || {};
  const { locale } = localization;

  const getComponent = useCallback(props => {
    switch (type) {
      case 'SMBSolutionsListWidget':
        return (
          <ToastProvider>
            <Suspense fallback={<LoaderWidget />}>
              <SolutionsListWidget {...props} />
            </Suspense>
          </ToastProvider>
        );
      default:
        return (
          <Suspense fallback={<LoaderWidget />}>
            <App {...props} />
          </Suspense>
        );
    }
  }, [type]);

  useEffect(() => {
    const fetchFetureFlags = async () => {
      try {
        const LDProvider = await asyncWithLDProvider({
          clientSideID: getLDClientSideID(),
          options: {
            bootstrap: 'localStorage',
            streaming: false,
          },
          reactOptions: {
            useCamelCaseFlagKeys: true,
          },
          user: {
            key: getUniqueUserKey(locale),
          },
        });
        setContainer(() => LDProvider);
      } catch (err) {
        console.error(err);
      } finally {
        setFetchingLD(false);
      }
    };
    fetchFetureFlags();
  }, [locale]);

  if (fetchingLD) {
    return <LoaderWidget fullScreen />;
  }

  return (
    <Container>
      <RootProvider localization={localization} resources={resources}>
        {getComponent(rest)}
      </RootProvider>
    </Container>
  );
};

SolutionsOverview.propTypes = {
  shell: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};

SolutionsOverview.defaultProps = {
  shell: {},
  type: null,
};

export default SolutionsOverview;
